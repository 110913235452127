export const cms: any = {
  action: "アクション",
  postList: "記事一覧",
  postEdit: "記事編集",
  postTitle: "タイトル",
  postStatus: "ステータス",
  postSummary: "概要",
  postEditorName: "編集者",
  postCreatedAt: "作成日時",
  postUpdatedAt: "更新日時",
  pageUrl: "ページURL",
  link: "リンク",
  featuredImage: "アイキャッチ画像",
  normalText: "本文テキスト",
  heading1: "見出し 1",
  heading2: "見出し 2",
  heading3: "見出し 3",
  heading4: "見出し 4",
  bulletList: "箇条書きリスト",
  numberedList: "番号付きリスト",
  checkList: "チェックリスト",
  quote: "引用",
  codeBlock: "コードブロック",
  strikethrough: "取り消し線",
  subscript: "下付き文字",
  superscript: "上付き文字",
  clearFormatting: "フォーマットクリア",
  image: "画像",
  table: "表",
  insertImage: "画像の挿入",
  insertTable: "表の挿入",
  numberOfRows: "行数",
  numberOfColumns: "列数",
  file: "ファイル",
  imageUrl: "画像URL",
  imageUpload: "アップロード画像",
  altText: "代替テキスト",
  altTextPlaceholder: "画像の説明など",
  embedTweet: "ツイートの埋め込み",
  embedYouTubeVideo: "YouTube動画の埋め込み",
  embedInstagram: "インスタグラムの埋め込み",
  tweet: "ツイート",
  youTubeVideo: "YouTube動画",
  instagram: "インスタグラム",
  addCaption: "キャプションを追加",
  removeCaption: "キャプションを削除",
  enterCaption: "キャプションを入力してください...",
  createPostSuccess: "記事を作成しました",
  updatePostSuccess: "記事を更新しました",
  errorOccurred: "エラーが発生しました",
  lineLink: "LINEリンク",
  insertLineLink: "LINEリンクの挿入",
  lineUrl: "LINEリンクURL",
  buttonText: "ボタンテキスト",
  buttonTextPlaceholder: "LINEで旅のプロに旅行相談",
  promotionText: "プロモーションテキスト",
  promotionTextPlaceholder: "お得な海外旅行を予約",
  backgroundColor: "背景色",
  insertRowAbove_one: "上に {{count}} 行追加",
  insertRowAbove_other: "上に {{count}} 行追加",
  insertRowBelow_one: "下に {{count}} 行追加",
  insertRowBelow_other: "下に {{count}} 行追加",
  insertColumnLeft_one: "左に {{count}} 列追加",
  insertColumnLeft_other: "左に {{count}} 列追加",
  insertColumnRight_one: "右に {{count}} 列追加",
  insertColumnRight_other: "右に {{count}} 列追加",
  deleteColumn: "列を削除",
  deleteRow: "行を削除",
  deleteTable: "表を削除",
  addRowHeader: "行ヘッダーを追加",
  addColumnHeader: "列ヘッダーを追加",
  removeRowHeader: "行ヘッダーを削除",
  removeColumnHeader: "列ヘッダーを削除",
  deletePostTitle: "記事の削除",
  deletePostDescription: "{{title}} を削除します",
  backToPostList: "記事一覧へ戻る",
  noChangesApplied: "変更を適用しない",
  scheduledOn: "{{date}} 公開予定",
  scheduledPost: "予約記事",
  searchForm: "検索フォーム",
  insertSearchForm: "検索フォームの挿入",
};
