export const unitEn: any = {
  durationOver1Hour: "{{hour}}:{{minute}}",
  durationUnder1Hour: "{{minute}}minutes",
  numberOfConnections_zero: "Direct",
  numberOfConnections_one: "{{count}} Transfer",
  numberOfConnections_other: "{{count}} Transfers",
  days: "{{count}}day",
  grade1: "one star",
  grade2: "two stars",
  grade3: "three stars",
  grade4: "four stars",
  grade5: "five stars",
  people: "{{num}} people",
  person_one: "{{count}} person",
  person_other: "{{count}} people",
  yearsOld: "{{num}} years old",
  rooms: "{{num}} rooms",
  bed_one: "{{count}} {{type}} bed",
  bed_other: "{{count}} {{type}} beds",
  sofaBed_one: "{{count}} {{type}} sofa bed",
  sofaBed_other: "{{count}} {{type}} sofa beds",
  futon_one: "{{count}} {{type}} futon",
  futon_other: "{{count}} {{type}} futons",
};
