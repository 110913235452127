export const hotel: any = {
  age: "Age",
  checkin: "Checkin",
  checkout: "Checkout",
  checkinDate: "Checkin date",
  checkoutDate: "Checkout date",
  destination: "Going to",
  hopeSeparateBed: "Separate beds",
  separateBeds: "One bed per person",
  mealRequest: "Hotel meal",
  nothing: "None",
  breakfast: "Breakfast",
  dinner: "Dinner",
  both: "Breakfast & Dinner",
  viewType: "View type",
  bedType: "Bed type",
  roomAmenity: "Room amenities",
  agreement: "I agree to the above",
  minRating: "Review rating",
};
