export const reservation: any = {
  firstName: "First Name",
  lastName: "Last Name",
  name: "Name",
  numberOfRooms: "{{number}} Room",
  roomIndex: "Room {{index}}",
  otherCharges: "Other charges:",
  totalPrice: "Trip Total",
  hotelFee: "Fee At The Property",
  priceBreakdown: "a breakdown of your price",
  breakdown: "Breakdown",
  email: "Email",
  creditCardNo: "Credit Card No",
  creditCardCvc: "Security Code",
  creditCardExpireMonth: "Expire Month",
  creditCardExpireYear: "Expire Year",
  phoneNumber: "Phone Number",
  address: "Address",
  travelerIndex: "Traveler {{index}}:",
  passenterIndex: "Passenger {{index}}:",
  primaryTraveler: "primary person",
  namePassportNotice: "Enter the names exactly as they appear on the passport.",
  nameOrderNotice:
    "Please note that you will not be able to board when entering your last and first name in reverse.",
  passportCountry: "Passport issuing country",
  dateOfBirth: "Date of birth",
  gender: "Gender",
  male: "Male",
  female: "Female",
  passportExpirationDate: "Passport date of expiry",
  passportNumber: "Passport number",
  nationalityCountryCode: "nationalityCountryCode",
  smokingRequest: "Smoking request",
  beddingRequest: "Bedding request",
  specialRequest: "Special request",
  agreement: "I agree to the above",
  numberOfGuests: "Number of Guests",
  roomAmenities: "Room Amenities",
  confirmNotice:
    "The reservation has not been completed yet. " +
    'After confirming the contents, click the "COMPLETE BOOKING" button at the bottom of the screen.',
};
