export const seo: any = {
  tarip: "TARIP",
  siteName: "たびチャット",
  title: "海外旅行ツアー、ホテル、フライトの予約",
  tariptitle: "海外旅行・国内旅行メディア",
  titleConsultationConditionEdit: "旅行相談の条件追加",
  titleConsultationNew: "旅行相談の条件入力",
  titleHotelIndex: "ホテル検索",
  titleHotelShow: "{{name}} ホテル予約",
  titleFlightIndex: "フライト検索",
  titleFlightShow: "フライト予約",
  titleReputation: "たびチャットの評判",
  titleEmailUserSignIn: "メールログイン",
  description:
    "たびチャットでは海外旅行のツアー、ホテル、フライトの予約ができます。チャットで相談するとコンシェルジュがプランの提案をいたします。",
  taripdescription:
    "TARIPは国内旅行、海外旅行の行きたいところが見つかる旅行メディアです",
  descriptionConsultationConditionEdit: "旅行相談の条件追加ができます",
  descriptionConsultationNew: "旅行相談の条件入力ができます",
  descriptionHotelIndex:
    "たびチャットならお得なプランや組み合わせでホテルが予約できます。",
  descriptionHotelShow:
    "{{name}}の宿泊、予約情報。たびチャットならお得なプランや組み合わせでホテルが予約できます。",
  descriptionReputation:
    "たびチャットを実際に使って海外旅行に行った方の評判/口コミをご紹介します。",
  descriptionEmailUserSignIn: "ログイン後に[予約申込|検索]ができます",
};
