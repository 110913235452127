export const cms: any = {
  action: "Action",
  postList: "Post list",
  postEdit: "Edit post",
  postTitle: "Title",
  postStatus: "Status",
  postSummary: "Summary",
  postEditorName: "Editor",
  postCreatedAt: "Created at",
  postUpdatedAt: "Updated at",
  pageUrl: "Page URL",
  link: "Link",
  featuredImage: "Featured image",
  normalText: "Normal",
  heading1: "Heading 1",
  heading2: "Heading 2",
  heading3: "Heading 3",
  heading4: "Heading 4",
  bulletList: "Bullet List",
  numberedList: "Numbered List",
  checkList: "Check List",
  quote: "Quote",
  codeBlock: "Code Block",
  strikethrough: "Strikethrough",
  subscript: "Subscript",
  superscript: "Superscript",
  clearFormatting: "Clear Formatting",
  image: "Image",
  table: "Table",
  insertImage: "Insert Image",
  insertTable: "Insert Table",
  numberOfRows: "No of rows",
  numberOfColumns: "No of columns",
  file: "File",
  imageUrl: "Image URL",
  imageUpload: "Image Upload",
  instagram: "Instagram",
  altText: "Alt Text",
  altTextPlaceholder: "Descriptive alternative text",
  embedTweet: "Embed tweet",
  embedYouTubeVideo: "Embed YouTube video",
  addCaption: "Add Caption",
  removeCaption: "Remove Caption",
  enterCaption: "Enter a caption...",
  createPostSuccess: "The post has been created",
  updatePostSuccess: "The post has been updated",
  errorOccurred: "Error occurred",
  lineLink: "LINE Link",
  insertLineLink: "Insert LINE Link",
  lineUrl: "LINE Link URL",
  buttonText: "Button Text",
  buttonTextPlaceholder: "Consulting with travel experts on LINE",
  promotionText: "Promotion Text",
  promotionTextPlaceholder: "Reserve affordable trips overseas",
  insertRowAbove_one: "Insert row above",
  insertRowAbove_other: "Insert {{count}} rows above",
  insertRowBelow_one: "Insert row below",
  insertRowBelow_other: "Insert {{count}} rows below",
  insertColumnLeft_one: "Insert column left",
  insertColumnLeft_other: "Insert {{count}} columns left",
  insertColumnRight_one: "Insert column right",
  insertColumnRight_other: "Insert {{count}} columns right",
  deleteColumn: "Delete column",
  deleteRow: "Delete row",
  deleteTable: "Delete table",
  addRowHeader: "Add row header",
  addColumnHeader: "Add column header",
  removeRowHeader: "Remove row header",
  removeColumnHeader: "Remove column header",
  deletePostTitle: "Delete the post",
  deletePostDescription: "Delete {{title}}",
  backToPostList: "Back to post list",
  noChangesApplied: "No changes applied",
  scheduledOn: "Scheduled on {{date}}",
  scheduledPost: "Scheduled post",
  searchForm: "Search Form",
  insertSearchForm: "Insert Search Form",
};
