export const dynamicPackage: any = {
  chooseFlight: "フライトの選択",
  chooseHotel: "ホテルの選択",
  chooseRoom: "部屋プランの選択",
  indicatorFlight: "はじめにフライトを選択してください",
  indicatorHotel: "次にホテルを選択してください",
  indicatorRoom: "部屋プランを選択してください",
  departureDateTime: "{{dateTime}}発",
  arrivalDateTime: "{{dateTime}}着",
};
