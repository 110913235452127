export const carouselFlight: any = {
  aboutUsingLcc: "LCCご利用について",
  lccNoteForPrice: `【事前座席指定】が有料
  【預け荷物】が有料
  機内食は機内購入
  予約後のキャンセル不可
  など、厳しい条件により、価格設定が安くなっております`,
  lccNoteForPriceChange: `また、残席状況の変化、セール期間終了などの理由により、\
  ご入金のタイミングによって料金が変動してしまう可能性がありますので、あらかじめご了承ください。`,
  lccNoteForPayment:
    "※ その場合、差額をご入金いただくか、全額ご返金させていただきます。",
  aboutShortLayoverRisk: "乗継リスクについて",
  ShortLayoverRisk: `空港で定められている最小乗り継ぎ時間をカバーしております。
その為、遅延などが発生した場合には、通常便が割り当てられますが、空席がないと翌日以降の便に振替られたりする可能性があります。
便の振替は現地空港の航空会社カウンターでしか、対応できないことが多く、私共でお手伝いできることが限られます。
乗継地の航空会社カウンターでやりとりが必要なため、何かとご面倒な事になりかねませんので、乗継時間は2時間以上は空けることをおすすめ致します`,
  airlineNoticeTitle: "{{airline}}の注意点",
  airportNoticeTitle: "{{airport}}の注意点",
  departureArrivalNoticeTitle: "発着時刻に関する注意点",
  earlyDepartureNotice:
    "早い時間に出発しますので、朝食時間や移動方法などご確認ください",
  lateArrivalNotice:
    "遅い時間に到着しますので、ホテルチェックインをご確認ください",
  usDomesticFlightsNoticeTitle: "アメリカ国内線の注意点",
};
