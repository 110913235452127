import { flightType } from "./flightType/ja";
import { flightClass } from "./flightClass/ja";
import { priceDisplayType } from "./priceDisplayType/ja";
import { utilityType } from "./utilityType/ja";
import { chatTabTypes } from "./chatTabTypes/ja";
import { searchType } from "./searchType/ja";
import { masterMessageTabTypes } from "./masterMessageTabTypes/ja";
import { reservationTypes } from "./reservationTypes/ja";
import { searchColumnType } from "./searchColumnType/ja";
import { hotelTabTypes } from "./hotelTabTypes/ja";
import { hotelSortType } from "./hotelSortType/ja";
import { hotelMealType } from "./hotelMealType/ja";
import { hotelBedSizeType } from "./hotelBedSizeType/ja";
import { flightSortType } from "./flightSortType/ja";
import { flightReservedSeatRequestType } from "./flightReservedSeatRequestType/ja";
import { optionalTourCategories } from "./optionalTourCategories/ja";
import { searchMealType } from "./searchMealType/ja";
import { hotelViewType } from "./hotelViewType/ja";
import { hotelSmokingTypes } from "./hotelSmokingTypes/ja";
import { hotelRoomAmenities } from "./hotelRoomAmenities/ja";
import { hotelAmenities } from "./hotelAmenities/ja";
import { hotelCategories } from "./hotelCategories/ja";
import { hotelSpokenLanguages } from "./hotelSpokenLanguages/ja";
import { flightFreeBaggageType } from "./flightFreeBaggageType/ja";
import { flightArrangementType } from "./flightArrangementType/ja";
import { passengerType } from "./passengerType/ja";
import { serviceType } from "./serviceType/ja";
import { flightOptionType } from "./flightOptionType/ja";
import { allianceType } from "./alllianceType/ja";
import { country } from "./country/ja";
import { countryCode } from "./countryCode/ja";
import { contractTypes } from "./contractTypes/ja";
import { airportCode } from "./airportCode/ja";
import { masterMessageGroupType } from "./masterMessageGroupType/ja";
import { crmMessageScheduleConditionType } from "./crmMessageScheduleConditionType/ja";
import { seo } from "./seo/ja";
import { ja as crmMessageScheduleStatus } from "./crmMessageScheduleStatus/ja.yml";
import { ja as inHouseNoticeTaskType } from "./inHouseNoticeTaskType/ja.yml";
import { ja as inHouseNoticeTaskDescription } from "./inHouseNoticeTaskDescription/ja.yml";
import { ja as lpType } from "./lpType/ja.yml";
import { ja as lpLayoutType } from "./lpLayoutType/ja.yml";
import { ja as externalArrangementType } from "./externalArrangementType/ja.yml";

export const constantJa = {
  flightType,
  flightClass,
  priceDisplayType,
  utilityType,
  chatTabTypes,
  searchType,
  masterMessageTabTypes,
  reservationTypes,
  searchColumnType,
  hotelTabTypes,
  hotelSortType,
  hotelMealType,
  hotelBedSizeType,
  flightSortType,
  flightReservedSeatRequestType,
  optionalTourCategories,
  searchMealType,
  hotelViewType,
  hotelSmokingTypes,
  hotelRoomAmenities,
  hotelAmenities,
  hotelCategories,
  hotelSpokenLanguages,
  flightFreeBaggageType,
  flightArrangementType,
  passengerType,
  serviceType,
  flightOptionType,
  allianceType,
  country,
  countryCode,
  contractTypes,
  airportCode,
  masterMessageGroupType,
  crmMessageScheduleConditionType,
  seo,
  crmMessageScheduleStatus,
  inHouseNoticeTaskType,
  inHouseNoticeTaskDescription,
  lpType,
  lpLayoutType,
  externalArrangementType,
};
