export const common = {
  flightArrangement: "Flight arrangement",
  flightArrangementNew: "Create flight arrangement",
  hotelArrangement: "Hotel arrangement",
  hotelArrangementNew: "Create hotel arrangement",
  optionalTourArrangement: "Optional tour arrangement",
  optionalTourArrangementNew: "Create optional tour arrangement",
  rentalCarArrangement: "Rental car arrangement",
  rentalCarArrangementNew: "Create rental car arrangement",
  wifiArrangement: "WiFi arrangement",
  wifiArrangementNew: "Create WiFi arrangement",
  railwayArrangement: "Railway arrangement",
  railwayArrangementNew: "Create railway arrangement",
  cruiseArrangement: "Cruise arrangement",
  cruiseArrangementNew: "Create cruise arrangement",
  insuranceArrangement: "Insurance arrangement",
  insuranceArrangementNew: "Create insurance arrangement",
  otherArrangement: "Other arrangement",
  otherArrangementNew: "Create other arrangement",
  discount: "Discount",
  discountNew: "Create discount",
  deleteMemo: "Delete this memo",
  deleteMemoDescription: "Delete the memo. Is it OK?",
  sendAllMessages: "Batch sending",
  sendAllMessagesDescription: "All messages will be sent. Is it OK?",
  refundBankTransferNotice:
    "Manually complete the bank refund.\nEnter the refund amount including the fee.",
  calendarMaxDaysMessage:
    "Please select again because the maximum number of days has been exceeded.",
  sendMessage: "Send the message",
  sendMessageDescription:
    'In general, use batch sending."Do you want to send a message?',
  flightArrangementInvoiceTitle: "Change flight arrangement to invoiced state",
  flightArrangementArrangeTitle:
    "Changed flight arrangements during temporary reservation",
  flightArrangementCancelTitle: "Cancel flight arrangement",
  flightArrangementVoidTitle: "Void flight arrangement",
  flightArrangementTicketingTitle: "Change flight arrangement during ticketing",
  flightArrangementWaitingForTicketedTitle:
    "Change flight arrangement to waiting for ticketed",
  flightArrangementTicketedTitle: "Change flight arrangement to ticketed",
  flightArrangementRefundingTitle:
    "Change flight arrangement during refund request",
  flightArrangementRefundedTitle: "Change flight arrangement to refunded",
  changeStatus: "Change status",
  defaultRefundedAmountToUserValue:
    "Refund by substracting the refund amount from refund fee amout",
  deleteAllLabeledDataSets: "Deleting all messages being created",
  deleteAllLabeledDataSetsDescription:
    "Delete all messages being created. Is it OK?",
  includeCodeshare: "Include code sharing",
  hotelArrangementInvoiceTitle: "Change hotel arrangement to invoiced state",
  hotelArrangementArrangeTitle:
    "Changed hotel arrangements during temporary reservation",
  hotelArrangementCancelTitle: "Cancel hotel arrangement",
  hotelArrangementCancelNotice:
    "The external site reservation will be cancelled.",
  hotelArrangementConfirmedTitle: "Change hotel arrangement to confirmed",
  hotelArrangementConfirmingTitle: "Change hotel arrangement to confirming",
  hotelArrangementConfirmedNotice:
    "Reservation is also confirmed for the external site, and payment occurs.",
  hotelArrangementRefundingTitle:
    "Change hotel arrangement during refund request",
  hotelArrangementRefundedTitle: "Change hotel arrangement to refunded",
  hotelArrangementRefundedNotice:
    "External site reservations will be canceled / refunded.",
  selectAllTravelers: "Select all travelers",
  selectAllPlans: "Select all plans",
  optionalTourArrangementInvoiceTitle:
    "Change optional tour arrangement to invoiced state",
  optionalTourArrangementArrangeTitle:
    "Changed optional tour arrangements during temporary reservation",
  optionalTourArrangementCancelTitle: "Cancel optional tour arrangement",
  optionalTourArrangementWaitingForConfirmationTitle:
    "Change optional tour arrangement to waiting for confirmation",
  optionalTourArrangementConfirmedTitle:
    "Change optional tour arrangement to confirmed",
  optionalTourArrangementRefundingTitle:
    "Change optional tour arrangement during refund request",
  optionalTourArrangementRefundedTitle:
    "Change optional tour arrangement to refunded",
  railwayArrangementInvoiceTitle:
    "Change railway arrangement to invoiced state",
  railwayArrangementArrangeTitle:
    "Changed railway arrangements during temporary reservation",
  railwayArrangementCancelTitle: "Cancel railway arrangement",
  railwayArrangementWaitingForArrangementTitle:
    "Change railway arrangement to waiting for arrangement",
  railwayArrangementConfirmedTitle: "Change railway arrangement to confirmed",
  railwayArrangementRefundingTitle:
    "Change railway arrangement during refund request",
  railwayArrangementRefundedTitle: "Change railway arrangement to refunded",
  selectDate: "Select {{dateName}}",
  customerTransferFeeNotice:
    "* If the transfer fee is paid by the customer, please fill in the transfer fee",
  sendConfirmation: "Send confirmation",
  sendCharge: "Send charge",
  submitFormError: "An error occurred in the input form",
  submitError: "An error occurred",
  regionGreat: "Great",
  regionGood: "Good",
  regionOthers: "Others",
  total: "Total",
  questionCondition: "Travel Consultation",
  none: "None",
  forOperator: "For Operator",
  changeDeadlineTitle: "Change Deadline",
  registerExternalPrice: "Register External Price",
  webLink: "{{text}} web",
  discountPrice: "{{price}} discount",
  discountPriceOn: "{{price}} discount on {{name}}",
  price: "Price",
  arrangeFee: "Arrangement Fee",
  totalPrice: "Total Price",
};
