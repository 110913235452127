import { common } from "./common/ja";
import { input } from "./input/ja";
import { reservation } from "./reservation/ja";
import { masterMessage } from "./masterMessage/ja";
import { hotel } from "./hotel/ja";
import { flight } from "./flight/ja";
import { optionalTour } from "./optionalTour/ja";
import { scenario } from "./scenario/ja";
import { chatNotification } from "./chatNotification/ja";
import { crmMessageSchedule } from "./crmMessageSchedule/ja";
import { masterMessageGroup } from "./masterMessageGroup/ja";
import { ja as form } from "./form/ja.yml";
import { ja as arrangementPayment } from "./arrangementPayment/ja.yml";
import { ja as arrangementRefund } from "./arrangementRefund/ja.yml";
import { ja as chat } from "./chat/ja.yml";
import { ja as user } from "./user/ja.yml";
import { ja as question } from "./question/ja.yml";
import { ja as inHouseNotice } from "./inHouseNotice/ja.yml";
import { ja as lp } from "./lp/ja.yml";
import { ja as emailChatUnit } from "./emailChatUnit/ja.yml";

export const labelJa = {
  common,
  input,
  reservation,
  masterMessage,
  hotel,
  flight,
  optionalTour,
  scenario,
  chatNotification,
  crmMessageSchedule,
  masterMessageGroup,
  form,
  arrangementPayment,
  arrangementRefund,
  chat,
  user,
  question,
  inHouseNotice,
  lp,
  emailChatUnit,
};
