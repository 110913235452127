export const dynamicPackage: any = {
  chooseFlight: "Choose your flight",
  chooseHotel: "Choose your hotel",
  chooseRoom: "Choose your room",
  indicatorFlight: "Please choose a flight first",
  indicatorHotel: "Please choose a hotel next",
  indicatorRoom: "Please choose a room plan",
  departureDateTime: "Departure {{dateTime}}",
  arrivalDateTime: "Arrival {{dateTime}}",
};
