export const carouselFlight: any = {
  aboutUsingLcc: "About using LCC",
  lccNoteForPrice: `Pricing is cheap due to tough conditions such as
    [Advance seat selection] is charged
    [Deposit Baggage] is charged
    Purchase in-flight meals
    No cancellation after reservation`,
  lccNoteForPriceChange: `In addition, please understand beforehand that the fee may fluctuate depending \
  on the timing of payment due to changes in the remaining seat status, the end of the sale period, and so on.`,
  lccNoteForPayment:
    "* In that case, I will refund the full amount, please pay the difference.",
  aboutShortLayoverRisk: "Translation pending",
  ShortLayoverRisk: `Translation pending`,
  airlineNoticeTitle: "Notice regarding {{airline}}",
  airportNoticeTitle: "Notice regarding {{airport}}",
  departureArrivalNoticeTitle: "Notice of Departure and Arrival Time",
  earlyDepartureNotice: "Departure time is early.",
  lateArrivalNotice: "Arrival time is late.",
  usDomesticFlightsNoticeTitle: "Notice for Domestic Flights in the U.S.",
};
