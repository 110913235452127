export const common = {
  checkinDate: "チェックイン日時",
  checkoutDate: "チェックアウト日時",
  checkin: "チェックイン",
  checkout: "チェックアウト",
  departureDate: "出発日",
  arrivalDate: "到着日",
  createdDate: "作成日",
  returnDate: "現地出発日",
  cabinClass: "座席クラス",
  destination: "目的地",
  query: "キーワード",
  origin: "出発地",
  date: "日程",
  schedule: "日程",
  people: "人数",
  numberOfRooms: "客室数",
  room: "部屋",
  hotelNumberOfAdult: "大人(18歳以上)",
  hotelNumberOfChildren: "子供(0～17歳)",
  flightNumberOfAdult: "大人(12歳以上)",
  flightNumberOfChildren: "子供(0～11歳)",
  agesOfChild: "お子様 {{index}}の年齢",
  stopCount: "経由回数",
  departureTime: "出発時刻",
  arrivalTime: "到着時刻",
  totalPrice: "合計価格",
  priceIncludingOption: "オプションも含む料金",
  airport: "空港",
  unspecified: "指定なし",
  flightNonstop: "直行便",
  flightStopUpTo: "{{count}} 回以下",
  flightOverNightUpTo: "{{count}} 泊以下",
  outboundCity: "出発地",
  returnCity: "現地出発地",
  departureCity: "出発地 {{index}}",
  lastArrivalCity: "最終到着地",
  price: "料金",
  additionalPrice: "追加料金",
  minMaxRange: "{{min}} ~ {{max}}",
  airportCity: "都市 {{index}}",
  recommendedFirst: "おすすめ順",
  cheapestFirst: "価格が低い順",
  highRankFirst: "評価が高い順",
  fastestFirst: "時間が短い順",
  addItinerary: "+ 区間を追加",
  removeItinerary: "- 区間を削除",
  chatUnitName: "チャット名",
  userId: "User Id",
  adult: "大人",
  child: "子供",
  infant: "幼児",
  common: "共通",
  authorOnly: "作成者のみ",
  bot: "ボット",
  createNew: "新規作成",
  list: "一覧",
  text: "テキスト",
  image: "画像",
  quickReply: "クイック返信",
  arrangementType: "手配タイプ",
  tourTitle: "ツアー名",
  tourId: "ツアーID",
  startDateTime: "開始日時",
  endDateTime: "終了日時",
  startDate: "開始日",
  endDate: "終了日",
  memo: "メモ",
  description: "詳細",
  cancelPolicy: "キャンセルポリシー",
  participant: "参加者",
  arrangementDone: "手配完了",
  totalFee: "合計料金",
  totalArrangementFee: "合計手配料金",
  reservationNumber: "予約番号",
  passwordNumber: "暗証番号",
  lastName: "姓",
  firstName: "名",
  address: "住所",
  phoneNumber: "電話番号",
  fee: "料金",
  arrangementFee: "手配手数料",
  arrangementFeeShort: "手数料",
  cancellationCharge: "キャンセル料",
  cancellationChargeIncludingFee: "キャンセル料(発券会社の手数料含む)",
  name: "氏名",
  status: "ステータス",
  gender: "性別",
  birthday: "生年月日",
  birthdayAmadeusFormat: "生年月日(Amadeus表記)",
  rentalCarTitle: "レンタカー名",
  rentalCarId: "レンタカーID",
  wifiTitle: "WiFi名",
  wifiId: "WiFi ID",
  railwayTitle: "鉄道名",
  railwayId: "鉄道ID",
  cruiseTitle: "クルーズ名",
  cruiseId: "クルーズID",
  insuranceTitle: "保険名",
  insuranceId: "保険ID",
  editingPreview: "▶︎ 編集内容のプレビュー",
  sortBy: "並び替え",
  priority: "優先度",
  dealingStep: "工程",
  userMemo: "ユーザーメモ",
  shortIntroductionSentences: "ショート紹介文",
  introductionSentences: "紹介文",
  recommendedSentencesList: "おすすめ一覧",
  hotelSummaryReviews: "口コミ要約",
  roomsLeft: "残り {{num}}部屋",
  cancelFree: "キャンセル料無料",
  tags: "タグ",
  enabling: "有効",
  priceRange: "予算",
  hotelRank: "ホテルランク",
  review: "レビュー",
  orMore: "以上",
  exist: "あり",
  notExist: "なし",
  pricePerPersonPerNight: "1名1泊あたりの価格",
  flightIntroduction: "フライト案内",
  flightReservation: "フライト予約",
  reservation: "予約",
  tripPlans: "旅行プラン",
  hotelReservation: "ホテル予約",
  dynamicPackageReservation: "DP予約",
  editReservation: "予約編集",
  hotelArrangementContents: "ホテル手配内容",
  flightArrangementContents: "フライト手配内容",
  optionalTourArrangementContents: "オプショナルツアー手配内容",
  rentalCarArrangementContents: "レンタカー手配内容",
  wifiArrangementContents: "WiFi手配内容",
  railwayArrangementContents: "鉄道手配内容",
  cruiseArrangementContents: "クルーズ手配内容",
  insuranceArrangementContents: "保険手配内容",
  otherArrangementContents: "その他手配内容",
  discountContents: "割引内容",
  userInformation: "ユーザー情報",
  paymentDetails: "支払い内容",
  chargeInformation: "請求情報",
  passenger: "搭乗者",
  guest: "お客様",
  companion: "お連れ様",
  hotelId: "ホテルID",
  hotelName: "ホテル",
  roomName: "部屋名",
  mealType: "食事タイプ",
  paymentType: "支払いタイプ",
  numberOfPeopleToStay: "宿泊者数",
  noCancellations: "キャンセル不可",
  itineraryNumber: "旅程番号",
  theTimeWhenARateOccurs: "料金が発生する時間",
  thisReservationIsManagedByBookingCom: "Booking.comで予約管理",
  thisReservationIsManagedByTaap: "Taapで予約管理",
  thisReservationIsManagedByJetstar: "Jetstarで予約管理",
  wholesaler: "ホールセラー",
  plan: "プラン",
  paymentName: "支払い名目",
  creditCardUrl: "クレジットURL",
  bankTransferUrl: "銀行振込URL",
  paymentMethod: "決済方法",
  paymentInformation: "支払情報",
  refundInformation: "返金情報",
  paymentPrice: "支払金額",
  refundPrice: "返金金額",
  refundPaymentType: "返金方法",
  arrangement: "手配",
  route: "経路",
  flight: "フライト",
  airline: "航空会社",
  marketingAirline: "マーケティング航空会社",
  operatingAirline: "オペレーティング航空会社",
  operatingAirlineShort: "運航会社",
  excludeAirline: "除外する航空会社",
  flightNumber: "便",
  departureAirport: "出発空港",
  arrivalAirport: "到着空港",
  transitAirport: "乗継空港",
  transfer: "乗継",
  airlineID: "航空会社ID",
  airportID: "空港ID",
  numberOfAdults: "大人の人数",
  tripType: "旅行タイプ",
  hotel: "ホテル",
  overseasFlight: "海外航空",
  domesticFlight: "国内航空",
  overseasDynamicPackage: "海外DP",
  domesticDynamicPackage: "国内DP",
  totalPeople: "人数(子供含む)",
  evaluation: "評価",
  toAirport: "{{name}}まで",
  remainingPrice: "残金",
  refundAmount: "返金額",
  refundAmountFromSupplier: "サプライヤーからの返金額",
  refundedDate: "返金日",
  refundedFeeAmount: "弊社が受取る返金手数料",
  refundedAmountToUser: "お客さんへの返金額",
  autoReply: "自動応答設定",
  reminder: "リマインダー",
  scheduledSending: "時間指定送信",
  paymentDate: "支払日",
  displayedPrice: "表示料金",
  difference: "差額",
  commissionBackPrice: "コミッションバック金額",
  commissionBackDate: "コミッションバック日",
  multiCity: "周遊",
  itineraries: "周遊",
  category: "カテゴリー",
  flightType: "フライトタイプ",
  section: "区間",
  request: "希望",
  maxPricePerPerson: "一人当たりの最大料金",
  minPricePerPerson: "一人当たりの最小料金",
  pricePerPerson: "一人当たりの料金",
  partner: "恋人",
  family: "家族",
  friend: "友達",
  single: "一人",
  business: "仕事",
  hopeTravel: "旅の希望",
  selectionCriteria: "選択基準",
  traveler: "旅行者",
  none: "無し",
  reminderDate: "日付",
  hour: "時",
  hours: "時間",
  minute: "分",
  hearingMode: "ヒアリングモード",
  notes: "備考",
  checkpoint: "チェックポイント",
  recommendedPoints: "おすすめポイント",
  planContents: "プラン内容",
  rating: "評価",
  flightDetails: "フライト詳細",
  age: "年齢",
  referenceNumber: "航空会社の予約番号",
  hotelReservationId: "ホテルの予約番号",
  hopeForHotel: "ホテルの希望",
  hopeForFlight: "フライトの希望",
  quickReplyActionData: "アクションデータ",
  quickReplyLabel: "ラベル",
  quickReplyDisplayText: "表示テキスト",
  freeBaggage: "無料預け入れ手荷物",
  additionalBaggage: "有料預け入れ手荷物",
  baggage: "預け入れ手荷物",
  itinerary0Baggage: "預け入れ手荷物 (往路)",
  itinerary1Baggage: "預け入れ手荷物 (復路)",
  otherPerFlightFees: "フライトごとのフィー",
  arrangementInvoicedPrice: "請求中のみ合計",
  arrangementPriceAndFeeSummary: "手配+手数料合計",
  paymentPriceSummary: "入金額合計",
  revenue: "収益",
  layover: "乗継時間",
  minLayover: "最小乗継時間",
  maxLayover: "最大乗継時間",
  useThis: "利用する",
  departureHour1: "出発時刻1",
  departureHour2: "出発時刻2",
  departureHour3: "出発時刻3",
  departureHour4: "出発時刻4",
  departureHour5: "出発時刻5",
  lastDepartureHour: "最終出発時刻",
  lastArrivalHour: "最終到着時刻",
  minPrice: "最低価格",
  maxPrice: "最高価格",
  lowestPrice: "最安価格",
  arrangementDate: "手配日",
  discountType: "割引タイプ",
  priceCurrency: "通貨",
  region: "地域",
  recordedDate: "計上日",
  or: "または",
  username: "ユーザー名",
  chatId: "チャットID",
  amenity: "部屋の設備",
  stayDuration: "旅行日数",
  addPeriod: "+ 期間を追加",
  message: "メッセージ",
  scheduledAt: "予定日時",
  meal: "食事",
  cleanliness: "清潔さ",
  comfort: "快適さ",
  service: "サービス",
  guestRoom: "客室",
  internet: "インターネット",
  onsen: "風呂・温泉",
  kosupa: "価格",
  location: "ロケーション",
  landscape: "眺望",
  quality: "クオリティ",
  transactionCompleteDate: "決済完了日",
  billingDate: "請求日",
  totalRefundedAmount: "合計返金額",
  totalRefundFee: "合計返金手数料",
  billerType: "請求元",
  billerName: "請求元会社名",
  billerCountryCode: "請求元の国コード",
  billerReservationNumber: "請求元の予約番号",
  serviceType: "サービスタイプ",
  hotelPrice: "ホテル費用",
  flightPrice: "フライト費用",
  optionalTourPrice: "オプショナルツアー費用",
  railwayPrice: "鉄道費用",
  additionalBaggagePrice: "預け入れ手荷物費用",
  deadlineDate: "期限",
  optionType: "オプションタイプ",
  content: "内容",
  autoInputTraveler: "旅行者姓名の自動入力",
  updatedDesc: "更新順 (新→古)",
  updatedAsc: "更新順 (古→新)",
  lastMessageCreatedDesc: "メッセージ作成順 (新→古)",
  lastMessageCreatedAsc: "メッセージ作成順 (古→新)",
  showAllFilters: "すべてのフィルタを表示",
  showLessFilters: "フィルタ表示を減らす",
  optional: "オプション",
  smoking: "喫煙",
  noSmoking: "禁煙",
  billingPrice: "請求料金",
  transferFee: "振込手数料",
  paymentFee: "決済手数料",
  members: "関連ユーザー",
  type: "タイプ",
  executeDateTime: "実施日時",
  remarks: "備考",
  targetDate: "指定日",
  daysToShift: "ずらす日数",
  label: "ラベル",
  displayText: "表示テキスト",
  button: "ボタン",
  carousel: "カルーセル",
  chatUnitStatus: "ヒアリングステータス",
  title: "タイトル",
  underage: "未成年",
  true: "はい",
  false: "いいえ",
  period: "期間",
  planId: "プランID",
  refundsToUser: "ユーザーへの返金",
  noRefundsToUser: "ユーザーに返金しない",
  noRefundsForUser: "ユーザーへの返金なし",
  edit: "編集",
  externalImage: "外部画像",
  transferDate: "振込日",
  required: "必須",
  checkpoints: "チェックポイント",
  deadline: "期限",
  relayEmail: "転送メール",
  operatorOnlyEmail: "OPのみ転送メール",
  relayToEmail: "転送先メール",
  priceDifferenceDisplayable: "差額の表示",
  showPriceDifference: "差額を表示する",
  uri: "URL",
  action: "アクション",
  noData: "データなし",
};
