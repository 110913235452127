export const search: any = {
  hotel: "Hotel",
  flight: "Flight",
  overseasflight: "Overseas Flight",
  overseasFlightHotel: "Overseas Flight\n+ Hotel",
  search: "Search",
  update: "Update",
  close: "Close",
  apply: "Apply",
  cancel: "Cancel",
  searchResults: "{{totalNum}} results",
  searchResultsPage: "{{startNum}} to {{endNum}} of {{totalNum}} results",
  filter: "Filter",
  map: "Map",
  confirm: "Confirm",
  done: "Done",
  selectDate: "Select {{dateName}}",
  calendarMaxDaysMessage:
    "Please select again because the maximum number of days has been exceeded.",
  popularDestinations: "Popular destinations",
  searchingHotels: "Searching for hotels...",
  searchingFlights: "Searching for flights...",
};
