export const buttonEn = {
  add: "ADD",
  save: "SAVE",
  create: "CREATE",
  select: "SELECT",
  edit: "EDIT",
  send: "SEND",
  sendLong: "SEND",
  sendAll: "SEND ALL",
  update: "UPDATE",
  remove: "REMOVE",
  removeAll: "REMOVE ALL",
  close: "CLOSE",
  execute: "EXECUTE",
  executeLong: "EXECUTE",
  register: "REGISTER",
  cease: "CEASE",
  cancel: "CANCEL",
  recreate: "RECREATE",
  search: "SEARCH",
  searchLong: "SEARCH",
  filterBy: "Filter by",
  displayMap: "Display Map",
  done: "DONE",
  createReservationRecord: "Create Record",
  establishTheSale: "Establish The Sale",
  returnThis: "RETURN",
  backToList: "◁ Back to list",
  addMessages: "◁ add messages",
  reserve: "RESERVE",
  reserveInfoOnly: "Reserve info only",
  reserveFlight: "Reserve a flight",
  reserveHotel: "Reserve a hotel",
  changeSearchMode: "Change search mode",
  refundPartial: "Refund partial",
  setAutoReply: "Set auto reply",
  showDetails: "Show Details",
  hideDetails: "Hide Details",
  origin: "DEP",
  destination: "DEST",
  schedule: "SCHED",
  people: "GUEST",
  itineraries: "MULTI",
  hideMemo: "HIDE MEMO",
  showMemo: "SHOW MEMO",
  searchHotel: "SEARCH HOTEL",
  searchNextHotel: "SEARCH NEXT HOTEL",
  createConsultation: "CREATE",
  updateConsultationCondition: "UPDATE",
  addConsultationCondition: "CREATE ADDITIONAL",
  removeLong: "REMOVE",
  detail: "DETAILS",
  researchHotel: "RESEARCH HOTEL",
  researchFlight: "RESEARCH FLIGHT",
  correctPrice: "CORRECT PRICE",
  commissionBack: "COMMISSION BACK",
  restoreReservationForm: "RESTORE RESERVATION FORM",
  displayLeft: "DISPLAY LEFT",
  displayCenter: "DISPLAY CENTER",
  displayRight: "DISPLAY RIGHT",
  addFirst: "ADD FIRST",
  addFirstShort: "ADD FIRST",
  setReminder: "SET REMINDER",
  setScheduledSending: "SET SCHEDULED SENDING",
  disableHearingMode: "DISABLE",
  carousel: "CAROUSEL",
  text: "TEXT",
  createMessage: "CREATE MESSAGE",
  createChargeInfoMessage: "CREATE CHARGE INFO MESSAGE",
  createTicketMessage: "CREATE TICKET MESSAGE",
  createPlanSummary: "CREATE SUMMARY",
  getAutoSuggestion: "AUTO SUGGESTION",
  abortReservation: "ABORT RESERVATION",
  creationOfReservationInfo: "Reservation Information",
  addQuickReplyItem: "Add Quick Reply Item",
  createPreReservationMessage: "CREATE RESERVATION MESSAGE",
  searchByDate: "Search by date",
  searchByPeriod: "Search by period",
  switchNormalLabeledDataSet: "Switch for sending",
  switchMemoLabeledDataSet: "Switch for memo",
  recreateCarousels: "Auto selection of same plan",
  void: "VOID",
  ticketing: "Ticketing",
  waitingForTicketed: "Waiting TKD",
  ticketed: "Ticketed",
  confirmed: "Confirmed",
  confirming: "Confirming",
  refunding: "Refunding",
  refunded: "Refunded",
  invoiced: "Invoiced",
  arranged: "Arranged",
  waitingForArrangement: "Waiting ARR",
  waitingForConfirmation: "Waiting CFM",
  bulkAction: "BULK ACTION",
  adjustPrice: "ADJUST PRICE",
  addPrice: "ADD PRICE",
  addRefund: "ADD REFUND",
  changeDeadline: "CHANGE D/L",
  addTraveler: "+ ADD TRAVELER",
  removeTraveler: "- REMOVE TRAVELER",
  preReserve: "PRE-BOOK",
  confirmReservation: "CONFIRM THE BOOKING",
  sendEmail: "SEND EMAIL",
  recreateByOneArrangement: "Recreate by one Arrangement",
  selectWithDate: "SELECT WITH DATE",
  addAction: "ADD ACTION",
  removeAction: "REMOVE ACTION",
  addCarousel: "ADD CAROUSEL",
  removeCarousel: "REMOVE CAROUSEL",
  removeThisMessageGroup: "REMOVE THIS MESSAGE GROUP",
  updateThisMessageGroup: "UPDATE THIS MESSAGE GROUP",
  addMessageGroup: "ADD MESSAGE GROUP",
  removeMessageGroup: "REMOVE MESSAGE GROUP",
  updateMessageGroup: "UPDATE MESSAGE GROUP",
  createNew: "CREATE NEW",
  addReply: "ADD REPLY",
  removeReply: "REMOVE REPLY",
  addKeyword: "ADD KEYWORD",
  removeMessage: "REMOVE MESSAGE",
  publishScenario: "PUBLISH SCENARIO",
  closeScenario: "CLOSE SCENARIO",
  inputActionData: "ADD ACTION DATA",
  searchOptionalTour: "SEARCH TOUR",
  reset: "RESET",
  duplicate: "DUPLICATE",
  addCondition: "ADD CONDITION",
  removeCondition: "REMOVE CONDITION",
  addCrmMessageSchedule: "ADD CRM MESSAGE SCHEDULE",
  updateCrmMessageSchedule: "UPDATE CRM MESSAGE SCHEDULE",
  moreActions: "More Actions",
  sendTestMessages: "Send Test Messages",
  registerPassport: "Register Passport",
  addBasicInfo: "Add Basic Info",
  addTour: "Add Tour",
  registerNew: "REGISTER NEW",
  change: "CHANGE",
  delete: "DELETE",
};
