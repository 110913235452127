export const reservation: any = {
  numberOfRooms: "客室数 {{number}}室",
  roomIndex: "客室 {{index}}",
  firstName: "名(英字)",
  lastName: "姓(英字)",
  name: "氏名(英字)",
  otherCharges: "その他の諸経費：",
  totalPrice: "合計料金",
  priceBreakdown: "料金内訳",
  breakdown: "内訳",
  hotelFee: "施設で支払う料金",
  email: "メールアドレス(半角英数字)",
  creditCardNo: "カード番号",
  creditCardCvc: "セキュリティコード",
  creditCardExpireMonth: "有効期限(月)",
  creditCardExpireYear: "有効期限(年)",
  phoneNumber: "電話番号(半角数字)",
  address: "住所",
  travelerIndex: "旅行者 {{index}} :",
  passengerIndex: "搭乗者 {{index}} :",
  primaryTraveler: "代表者",
  namePassportNotice:
    "パスポートに記載されているとおりに名前を入力してください",
  nameOrderNotice: "姓名を逆に入力されるとご搭乗いただけないためご注意ください",
  passportCountry: "パスポート発行国",
  dateOfBirth: "生年月日",
  gender: "性別",
  male: "男性",
  female: "女性",
  passportExpirationDate: "パスポート有効期間満了日",
  passportNumber: "パスポート番号",
  nationalityCountryCode: "国籍",
  smokingRequest: "禁煙/喫煙 リクエスト",
  beddingRequest: "ベッドタイプ リクエスト",
  specialRequest: "特別リクエスト",
  agreement: "上記の内容に同意する",
  numberOfGuests: "宿泊者内訳",
  roomAmenities: "部屋設備",
  confirmNotice:
    'まだ予約は完了しておりません。内容をご確認の上、画面下部の"予約を完了する"ボタンを押してください。',
};
