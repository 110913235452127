export const lp: any = {
  searchFlightByDestinationTitle: "Flight Destinations in {{destination}}",
  flightReferencePriceListTitle: "Popular flights to {{destination}}",
  destinationAbout: "About {{destination}}",
  destinationBestSeason: "Best season for {{destination}}",
  conciergeComment: "Concierge comments",
  destinationMainAirlines: "Main airlines to {{destination}}",
  accessToCity: "Access to the city",
  searchHotelByDestinationTitle: "Search hotels in{{destination}}",
  hotelRecommendedListTitle: "Recommended hotels in {{destination}}",
  surroundingRegions: "Surrounding regions",
};
